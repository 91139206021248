<template>
    <div class="animated fadeIn">
        <h1>Personen</h1>
        <div class="row align-items-center float-right col-2">
            <router-link style="margin-bottom: 10px" :to="'/blog/editPerson/-1'" tag="button" class="btn margin-bottom btn-primary btn-block">Person anlegen</router-link>
        </div>
        <div id="persons">
            <v-client-table ref="table" :api-mode="false" :data="persons" :columns="columns" :options="options">
                <template slot="edit" slot-scope="props">
                    <router-link :to="'/blog/editPerson/'+props.row.id">
                        <a class="fa fa-edit fa-lg"></a>
                    </router-link>
                    &nbsp;
                    <a class="fa fa-trash fa-lg text-primary" @click="warningModal = true, selectedPerson = props.row.id"></a>
                </template>
            </v-client-table>
        </div>

        <!-- Modal Component -->
        <b-modal title="Person löschen?" class="modal-warning" v-model="warningModal" @ok="deletePerson()" ok-variant="warning">
            Wollen Sie die Person wirklich löschen?
        </b-modal>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {ClientTable} from 'vue-tables-2'
    import {mapGetters} from "vuex";

    Vue.use(ClientTable)


    export default {
        name: 'persons',
        data () {
            return {
                warningModal: false,
                selectedPerson: 0,
                nrOfEntries: 0,
                columns: ['firstname', 'lastname', 'edit'],
                options: {
                    headings: {
                        edit: ''
                    },
                    filterable: false,
                    sortable: [],
                    sortIcon: {
                        base: 'fa',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down',
                        is: 'fa-sort'
                    },
                    texts: {
                        //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                        count: '',
                        filterPlaceholder: 'Search query',
                    },
                    pagination: {
                        nav: 'scroll'
                    },
                }
            }
        },
        computed: {
            ...mapGetters({
                persons: 'person/getPersons',
            })
        },
        mounted() {
            this.$store.dispatch('person/loadAllPersons')
        },
        methods: {
            deletePerson() {
                this.$store.dispatch('person/deletePerson', this.selectedPerson )
            },
        },

    }
</script>
